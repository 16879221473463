import {TableBase} from "../../tables/table_base";

class LastInfringement extends TableBase {
  constructor() {
    super('last_infringement');
  }

  paintTable($infringements, infringements) {
    $infringements.empty()

    for (const id in infringements) {
      const row = infringements[id];
      const $row = $('<tr>').attr('data', row.id);
      const $infoCell = $('<span>').attr({'data-tbl': 'usr', 'data-id': row.id}).addClass('hover-preview').text(row.driver_name);
      const $nameCell = $('<td>').append($infoCell);

      // remove date as its already a column
      if( row.message[2] === '/' ) {
        row.message = row.message.slice(18);
      }

      const levelConfig = app.OPTIONS?.infringement_levels[row.infringement_level];
      let level = '';

      if( levelConfig ) {
        level = `<i class="${levelConfig.class} ${levelConfig.colour}"></i> ${levelConfig.name}`;
      }

      const eventStart = moment(row.event_start)
      const eventLink = $('<a>').attr({
        target: '_blank',
        href: `${app.CACHE.URL_ADMIN}tco_drv_activity/${row.tacho_driver_id}/${eventStart.format('DD-MM-YYYY')}#I${eventStart.format('YYYYMMDDHHmmss')}`
      }).append($('<i>').addClass('fa fa-link'))
      $row
        .append($nameCell)
        .append($('<td>').html(level).addClass())
        .append($('<td>').text(row.message))
        .append($('<td>').text(eventStart.format(formatDatetime)))
        .append($('<td>').append(eventLink))

      $infringements.append($row);
    }

    if( !infringements?.length ){
      $infringements.html('<tr><td colspan="5">No infringements to display</td></tr>');
    }
  }
}

$(() => {
  if(app && app.DASHBOARD_CORE) {
    new LastInfringement();
  } else {
    console.warn('DashboardCore not initalized.');
  }
});
